import { BACKEND_API } from "@/config/constants";
import { apiClient } from "@/helpers/request";
import { IAskMatches } from "@/pages/search/interfaces";

export const getImageUploadUrl = (udid: string): any => {
  return apiClient(BACKEND_API, "/v1/question/generate-question-image-upload-url", {
    method: "POST",
    body: JSON.stringify({
      class: "12",
      subject: "physics",
      chapter: "magnetish",
      doubt: "maths",
      udid,
      locale: "en",
      content_type: "image/png",
      file_ext: ".png",
      question: "WEB",
    })
  });
}

export const uploadQuestionImage = (url: any, image: any): any => {
  return apiClient(url, "", {
    method: "PUT",
    body: image,
  });
}

export const getMatches = (udid: string, uploadedQuestionImageName: any, uploadedQid: any, question_text?: string): Promise<{  data: IAskMatches }> => {
  return apiClient(BACKEND_API, "/v10/questions/ask", {
    method: "POST",
    body: JSON.stringify({
      question_image: "image_url",
      uploaded_image_name: uploadedQuestionImageName,
      question: "WEB",
      limit: 20,
      udid,
      uploaded_image_question_id: uploadedQid,
      metadata: false,
      question_text
    }),
    headers: {
      "x-auth-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTg4MjI2LCJpYXQiOjE3MjUwMDk0NTgsImV4cCI6MTc1NjU0NTQ1OH0.wWfNA13X7aFSdy69FZNC6QN5rdK3NR_S8LDvITv2nWM"
    },
  });
}
